import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text, Heading } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import sommerLogo from 'assets/icons/sommer-icon.png'

type Props = {
  heading1: string
  heading2: string
  description1: string
  description2: string
}

const Section = styled.section`
  width: 100%;
  padding: 0 0 40px;
  background-color: ${({ theme }) => theme.colors.white};
`

const StyledContainer = styled(Container)``

const InnerWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const Hr = styled.div`
  width: 80px;
  height: 8px;
  margin: 15px auto 10px auto;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 3px;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    margin: 0;
  }
`

const DescriptionWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    width: 68%;

    &:last-child {
      width: 28%;
      padding-left: 30px;
    }
  }
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  br {
    display: none;
  }

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-between;

    br {
      display: block;
    }
  }
`

const LogoWrapper = styled.div`
  max-width: 220px;
`

const About: React.FC<Props> = ({
  heading1,
  heading2,
  description1,
  description2,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <Heading
          as="h2"
          size={28}
          weight={600}
          align={lg ? 'left' : 'center'}
          themecolor="primary"
          margin="20px"
          dangerouslySetInnerHTML={{ __html: heading1 }}
        />
        <InnerWrapper>
          <DescriptionWrapper>
            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: description1 }}
            />
          </DescriptionWrapper>

          <DescriptionWrapper>
            <Hr />
            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
          </DescriptionWrapper>
        </InnerWrapper>
        <BottomWrapper>
          <Heading
            as="h2"
            size={28}
            weight={600}
            align={lg ? 'left' : 'center'}
            themecolor="primary"
            margin="20px"
            dangerouslySetInnerHTML={{ __html: heading2 }}
          />
          <LogoWrapper>
            <img src={sommerLogo} alt="" />
          </LogoWrapper>
        </BottomWrapper>
      </StyledContainer>
    </Section>
  )
}

export default About
