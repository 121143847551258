import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/AutomatykaBram/About'
import Automation from 'components/layout/Sections/Pages/AutomatykaBram/Automation'
import VideoSection from 'components/layout/Sections/Video'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Automatyka bram',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Automatyka bram',
    },
  ],
}

const AutomatykaBramPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.automatykaBram
  const PAGE_SEO = data?.wpPage?.seo

  const automationAdvantages1 = React.useMemo(
    () => PAGE?.adv?.map((advantage) => advantage?.advSingle),
    []
  )
  const automationAdvantages2 = React.useMemo(
    () =>
      PAGE?.advFourthSection?.map(
        (advantage) => advantage?.advFourthSectionSingle
      ),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={PAGE.heroImage.localFile.childImageSharp.gatsbyImageData}
        imgDesktop={PAGE.heroImage.localFile.childImageSharp.gatsbyImageData}
        headingMobile={PAGE?.title}
        headingDesktop={PAGE?.title}
        breadcrumbs={breadcrumbs}
      />
      <About
        heading1={PAGE?.titleSecond}
        heading2="Otwieraj bramę (i nie tylko) za pomocą<br/> aplikacji w Twoim telefonie"
        description1={PAGE?.descriptionLeft}
        description2={PAGE?.descriptionRight}
      />
      <Automation
        heading={PAGE?.titleThirdSection}
        description1={PAGE?.decriptionThirdSection}
        description2={PAGE?.descriptionFourthSection}
        advantages1={automationAdvantages1}
        advantages2={automationAdvantages2}
        img1={{
          src: PAGE?.imgThirdSection.localFile.childImageSharp.gatsbyImageData,
          alt: PAGE?.imgThirdSection.altText,
        }}
        img2={{
          src: PAGE?.imgFourthSection.localFile.childImageSharp.gatsbyImageData,
          alt: PAGE?.imgFourthSection.altText,
        }}
      />
      {PAGE?.videoUrl && (
        <VideoSection videoUrl={PAGE?.videoUrl} marginBottom />
      )}
    </Layout>
  )
}

export default AutomatykaBramPage

export const query = graphql`
  query AutomatykaBramPage {
    wpPage(slug: { regex: "/automatyka-bram/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
        twitterTitle
        twitterDescription
        opengraphTitle
      }
      id
      automatykaBram {
        decriptionThirdSection
        descriptionFourthSection
        descriptionLeft
        descriptionRight
        fieldGroupName
        title
        titleSecond
        titleThirdSection
        adv {
          advSingle
        }
        advFourthSection {
          advFourthSectionSingle
        }
        videoUrl
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        imgFourthSection {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        imgThirdSection {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
