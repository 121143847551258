import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text, Heading } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  heading: string
  description1: string
  description2: string
  advantages1: string[]
  advantages2: string[]
  img1: {
    src: ImageDataLike
    alt: string
  }
  img2: {
    src: ImageDataLike
    alt: string
  }
}

const Section = styled.section`
  width: 100%;
  padding: 0 0 40px 0;
  background-color: ${({ theme }) => theme.colors.white};
`

const StyledContainer = styled(Container)``

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray200};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    padding: 20px 50px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  max-width: 400px;
  margin: 0 auto;
`

const AdvantagesWrapper = styled.div``

const StyledText = styled(Text)`
  margin-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary300};
    border-radius: 3px;

    ${({ theme }) => theme.media.lg.min} {
      transform: translateX(0);
      left: 0;
    }
  }
`

const ContentWrapper = styled.div`
  padding: 20px;

  ${({ theme }) => theme.media.lg.min} {
    padding: 20px 0 20px 50px;

    ${Heading} {
      padding-left: 30px;
    }

    ${AdvantagesWrapper} {
      padding-left: 30px;
    }

    ${StyledText} {
      margin-left: 30px;
    }
  }
`

const DescriptionWrapper = styled.div`
  position: relative;
  padding-bottom: 20px;

  ${({ theme }) => theme.media.lg.min} {
    padding-left: 30px;
    padding-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary300};
    border-radius: 3px;

    ${({ theme }) => theme.media.lg.min} {
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
    }
  }
`

const StyledAdvantageText = styled(Text)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black100};
  padding: 5px 0;
`

const BottomWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray200};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    padding: 20px 50px;

    ${ImageWrapper} {
      order: 1;
    }
  }
`

const BottomContentWrapper = styled.div`
  padding: 20px;

  ${({ theme }) => theme.media.lg.min} {
    padding: 20px 50px 20px 0;
  }
`

const About: React.FC<Props> = ({
  heading,
  description1,
  description2,
  advantages1,
  advantages2,
  img1,
  img2,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <TopWrapper>
          <ImageWrapper>
            <LazyImage src={img1.src} alt={img1.alt} />
          </ImageWrapper>
          <ContentWrapper>
            <Heading
              as="h3"
              size={24}
              weight={600}
              align={lg ? 'left' : 'center'}
              themecolor="primary"
              margin="20px"
              transform="uppercase"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <DescriptionWrapper>
              <Text
                weight={500}
                themecolor="black"
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: description1 }}
              />
            </DescriptionWrapper>
            <StyledText
              weight={700}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              transform="uppercase"
              size={18}
            >
              Zalety
            </StyledText>
            <AdvantagesWrapper>
              {advantages1.map((el) => (
                <StyledAdvantageText dangerouslySetInnerHTML={{ __html: el }} />
              ))}
            </AdvantagesWrapper>
          </ContentWrapper>
        </TopWrapper>
        <BottomWrapper>
          <ImageWrapper>
            <LazyImage src={img2.src} alt={img2.alt} />
          </ImageWrapper>
          <BottomContentWrapper>
            <Text
              weight={500}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
            <StyledText
              weight={700}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              transform="uppercase"
              size={18}
            >
              Zalety
            </StyledText>
            <AdvantagesWrapper>
              {advantages2.map((el) => (
                <StyledAdvantageText dangerouslySetInnerHTML={{ __html: el }} />
              ))}
            </AdvantagesWrapper>
          </BottomContentWrapper>
        </BottomWrapper>
      </StyledContainer>
    </Section>
  )
}

export default About
